import React from 'react';
import {TFunction} from "i18next";
import {Heading} from "./heading";
import styled from "styled-components";

type Props = {
    t: TFunction
    errorMsg: string | undefined
}

const ErrorDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

function ErrorView(props: Props) {

    const t = props.t;
    const errorMsg = props.errorMsg || t('generic-error-msg');

    return (
        <ErrorDiv>
            <Heading>{t('error')}</Heading>
            <p>{errorMsg}</p>
        </ErrorDiv>
    );
}

export default ErrorView;
