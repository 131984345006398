import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getRandomInt} from "./util";
import {Data} from "./types/data";
import ErrorView from "./components/error-view";
import ScrollToTop from "./components/scroll-to-top";
import {Route, Routes} from "react-router-dom";
import {Wrapper} from "./components/wrapper";
import {GlobalStyles} from "./global-styles";

const Nav = React.lazy(() => import('./components/nav'));
const Loader = React.lazy(() => import('./components/loader'));
const Footer = React.lazy(() => import('./components/footer'));
const ErrorPage = React.lazy(() => import('./pages/error-page'));
const AboutPage = React.lazy(() => import('./pages/about-page'));
const ReleasesPage = React.lazy(() => import('./pages/releases-page'));
const ArtistsPage = React.lazy(() => import('./pages/artists-page'));
const PrivacyPage = React.lazy(() => import('./pages/privacy-page'));
const ReleasePage = React.lazy(() => import('./pages/release-page'));
const ArtistPage = React.lazy(() => import('./pages/artist-page'));

function App() {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [data, setData] = useState<Data | undefined>(undefined);

    useEffect(() => {
        if (loading) return;
        setLoading(true);
        fetch("/data.json?" + getRandomInt(1000, 9999))
            .then(response => response.json())
            .then((jsonData) => {
                setLoading(false);
                setError(undefined);
                setData(jsonData);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <GlobalStyles />
            <Nav t={t}/>
            {
                (error && <Wrapper>
                    <ScrollToTop/>
                    <ErrorView t={t} errorMsg={t('data-error-text')}/>
                </Wrapper>) ||
                <ErrorPage t={t}>
                    {(data && <Routes>
                        <Route path={'/'} element={<AboutPage t={t}/>} />
                        <Route path={'/releases'} element={<ReleasesPage t={t} data={data}/>} />
                        <Route path={'/artists'} element={<ArtistsPage t={t} data={data}/>} />
                        <Route path={'/privacy'} element={<PrivacyPage t={t}/>} />
                        <Route path={'/release/:ean'} element={<ReleasePage t={t} data={data} />} />
                        <Route path={'/artist/:slug'} element={<ArtistPage t={t} data={data} />} />
                    </Routes>) || <Loader t={t}/>}
                </ErrorPage>
            }
            <Footer t={t}/>
        </>
    );
}

export default App;
