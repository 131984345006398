import React from 'react';
import KFLogo from 'jsx:../assets/images/logo.svg';
import {TFunction} from "i18next";
import styled from "styled-components";

type Props = {
    t?: TFunction
}

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LoadingText = styled.p`
  font-weight: 100;

  &:after {
    display: inline;
    content: '\\00a0\\00a0\\00a0';
    animation: loading-dots 3s linear infinite;
    animation-delay: 0.2s;
  }

  @keyframes loading-dots {
    0% {
      content: '.\\00a0\\00a0';
    }
    40% {
      content: '..\\00a0';
    }
    60% {
      content: '...';
    }
    100% {
      content: '\\00a0\\00a0\\00a0';
    }
  }
`;

const StyledLogo = styled(KFLogo)`
  width: 92px;
  height: 92px;

  path {
    stroke-dasharray: 908;
    stroke-dashoffset: 908;
    animation: draw 1.8s ease-in-out infinite;
    animation-delay: 0.4s;
  }

  @keyframes draw {
    0% {
      stroke-dashoffset: 900;
    }
    40% {
      stroke-dashoffset: 0;
    }
    60% {
      stroke-dashoffset: 900;
    }
    100% {
      stroke-dashoffset: 908;
    }
  }
`;

function Loader(props: Props) {
    const loadingText = props.t !== undefined ? props.t('loading') : 'Loading';

    return (
        <LoaderWrapper>
            <StyledLogo id={'loader'} alt={loadingText + '…'}/>
            <LoadingText>{loadingText}</LoadingText>
        </LoaderWrapper>
    );
}

export default Loader;
