import * as React from 'react';
import {FunctionComponent, PropsWithChildren} from 'react';
import styled from "styled-components";

const HeadingComponent: FunctionComponent<PropsWithChildren & {className?: string}> = (props) => {
  return (
    <h1 className={props.className}>
        {props.children}
    </h1>
  );
}

export const Heading = styled(HeadingComponent)`
  font-size: 2.5em;
`;
