/**
 * Function that turns a string into a slugged value, suitable for using in links, etc.
 * @param unsluggedValue the original value
 */
export function toSlug(unsluggedValue: string) {
    return (unsluggedValue + '')
        .split(' ').join('-') // not every browser knows 'replaceAll()' so we'll have to hack around that...
        .toLowerCase();
}

/**
 * Function that returns a random int between min and max.
 * @param min the minimum value
 * @param max the maximum value
 */
export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


export function formatTimeAgo(date: Date): string {
    const now = new Date();
    const timeDifference = now.getTime() - date.getTime();

    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const weeksDifference = Math.floor(daysDifference / 7);
    const monthsDifference = Math.floor(daysDifference / 30);
    const yearsDifference = Math.floor(daysDifference / 365);

    let releaseDistanceString = '';

    if (yearsDifference > 0) {
        releaseDistanceString = `${yearsDifference} year${yearsDifference > 1 ? 's' : ''} ago`;
    } else if (monthsDifference > 0) {
        releaseDistanceString = `${monthsDifference} month${monthsDifference > 1 ? 's' : ''} ago`;
    } else if (weeksDifference > 0) {
        releaseDistanceString = `${weeksDifference} week${weeksDifference > 1 ? 's' : ''} ago`;
    } else if (daysDifference > 1) {
        releaseDistanceString = `${daysDifference} days ago`;
    } else if (daysDifference === 1) {
        releaseDistanceString = 'yesterday';
    } else {
        releaseDistanceString = 'today';
    }

    return releaseDistanceString
}