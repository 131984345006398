import {createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
:root {
  --blur: blur(8px);

  /* Breakpoints */
  --break-width-md: 850px;
  --break-width-sm: 500px;
  --break-width-xs: 300px;
  --break-width-lg: 1220px;
  --break-width-xl: 1750px;

  /* Navbar */
  --navbar-height: 64px;
  --navbar-height-mobile: 90px;
  --navbar-height-xs: 140px;
  --navbar-padding: 16px;
  --navbar-margin: calc(var(--navbar-height) + 8px + var(--navbar-padding));
  --navbar-margin-md: calc(var(--navbar-height-mobile) + 8px + var(--navbar-padding));
  --navbar-margin-xs: calc(var(--navbar-height-xs) + 8px + var(--navbar-padding));
  --navbar-logo-size: 52px;

  --cover-size: 320px;
  --cover-size-xs: calc(var(--cover-size) / 2);

  --artist-link-width: 320px;

  --form-input-width: 400px;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  position: relative;
  background-color: black;
  color: white;
  font-family: "Fira Code", monospace;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: var(--navbar-margin) 0 64px;
  margin: 0;

  min-height: 350px;

  @media (max-width: 850px) {
    padding-top: var(--navbar-margin-md);
  }

  @media (max-width: 300px) {
    padding-top: var(--navbar-margin-xs);
  }
}
`;
